import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const ListBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth={block.align_left ? "content-left" : "content"}
      showHeadline="true"
      blockPadding="py-8 md:py-16"
    >
      <div className={block.headline?.length > 0 ? "pt-8" : ""}>
        <ul>
          {block.list_items?.length > 0 &&
            block.list_items.map((item, index) => (
              <li className="flex flex-row mb-6 last:mb-0">
                {block.icon?.filename?.length > 0 ? (
                  <Image
                    className="block object-contain w-8 h-8 mr-4 -mt-0.5 origin-center"
                    image={block.icon}
                  />
                ) : (
                  <span className="flex-shrink-0 block w-4 h-4 mt-1 mr-4 bg-primary"></span>
                )}
                <Richtext className="block" text={item.text} />
              </li>
            ))}
        </ul>
      </div>
    </BlockWrapper>
  )
}

export default ListBlock
